import { render, staticRenderFns } from "./ClientSummaryReport.vue?vue&type=template&id=72cbcc23&scoped=true&"
import script from "./ClientSummaryReport.vue?vue&type=script&lang=js&"
export * from "./ClientSummaryReport.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "72cbcc23",
  null
  
)

export default component.exports